//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import waitForMs from "@/helpers/wait-for-ms";

export default {
    data() {
        return {
            invalid_url: false
        };
    },

    async mounted() {
        try {
            await waitForMs(2000);

            const r = await this.$axios.$get(`/redirects/${this.$route.params.hash}/url`, {
                supress_errors: true
            });
            window.location.href = r.url;
        } catch (err) {
            this.invalid_url = true;
            console.error(err);
        }
    }
};
